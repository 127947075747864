.App {
  text-align: left;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3vmin;
  color: white;
}

.Header-text {
  padding-left: 0vmin;
  margin-top: 0;
  font-size: calc(16px + 4vmin);
}

.App-logo {
  height: 18vmin;
  pointer-events: none;
}

.Paragraph {
  /* border: #F00C18 solid; */
  /* border-width: 1vmin; */
  /* border-style: dotted; */
  /* border-radius: 25px; */
  max-width: 75vw;
  padding: 6vmin 6vmin 5vmin 6vmin;
  font-weight: 500;
  background: 
  linear-gradient(90deg, #F00C18 50%, transparent 0) repeat-x,
  linear-gradient(90deg, #F00C18 50%, transparent 0) repeat-x,
  linear-gradient(0deg, #F00C18 50%, transparent 0) repeat-y,
  linear-gradient(0deg, #F00C18 50%, transparent 0) repeat-y;
background-size: 16px 8px, 16px 8px, 8px 16px, 8px 16px;
background-position: 0 0, 0 100%, 0 0, 100% 0;}

.Paragraph:hover {
  animation: linearGradientMove .3s infinite linear;
}

.Footer {
  border-top: #F00C18 double 1vmin;
  padding-top: 4vmin;
  text-align: center;
}

.App-header {
  background: hsla(203, 100%, 55%, 1);

  background: linear-gradient(45deg, hsla(203, 100%, 55%, 1) 66%, hsla(192, 100%, 54%, 1) 88%);
  
  background: -moz-linear-gradient(45deg, hsla(203, 100%, 55%, 1) 66%, hsla(192, 100%, 54%, 1) 88%);
  
  background: -webkit-linear-gradient(45deg, hsla(203, 100%, 55%, 1) 66%, hsla(192, 100%, 54%, 1) 88%);
  
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#19A5FF", endColorstr="#13D1FF", GradientType=1 );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  line-height: 6vh;
}

.App-link {
  color: #F00C18;
}

.List {
  margin-block-start: 0em;
  list-style-type: none;
  padding: 0 ; 
  margin: 0;
}

.List-item {
  list-style-type: none;
  padding-bottom: 20px ; 
  margin: 0;
}

@keyframes linearGradientMove {
  100% {
      background-position: 16px 0, -16px 100%, 0 -16px, 100% 16px;
  }
}